// Pacakages
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';

// Components
import Coach from './Coach';

// Data
import coachesData from '../../data/coachesData';

// Animations
import { parentAnimation } from '../../data/animationData';

export default function CoachesContent() {

    const data = coachesData();
    const coachImages = useStaticQuery(graphql`
        query coachImages {
            coachImgs: allFile(filter: {sourceInstanceName: {eq: "coaches"}}) {
            nodes {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
                id
                }
                relativePath
            }
            }
        }`);
    return (
        <motion.div className="coaches-content content-wrapper" initial="before" animate="after" variants={parentAnimation}>
            {data.map(c => 
                <Coach
                    key={c.id}
                    coach={c}
                    image={coachImages.coachImgs.nodes.find(i => i.relativePath == c.image).childImageSharp.gatsbyImageData}
                ></Coach>
            )}
        </motion.div>
    );
}