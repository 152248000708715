// Packages
import React from 'react';

// Components
import Button from './Button';

export default function FooterContact({bg_color, fg_color}) {

    return (
        <div className={`footer-contact-section ${fg_color}-fg ${bg_color}-bg content-wrapper`}>
            <h3 className={`${fg_color}-fg`}>
                READY TO START TRAINING WITH THE BEST IN EDMONTON?
            </h3>
            <Button 
                text="CONTACT US"
                link="/contact"
                bg_color={`${fg_color}-bg`}
                fg_color={`${bg_color}-fg`}
            ></Button>
        </div>
    )
     
}