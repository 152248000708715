// Packages
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// Components
import RedLinedHeader from './RedLinedHeader';
import TeamGaloImage from './TeamGaloImage';

export default function PageHeader({title, text, image}) {
    
    const headerImage = useStaticQuery(graphql`
        query headerImages {
            headerImgs: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
            nodes {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
                id
                }
                relativePath
            }
            }
        }
    `).headerImgs.nodes.find(i => i.relativePath === image).childImageSharp.gatsbyImageData;

    
    return (
        <div className="page-header content-wrapper">
            <div className="page-header-image-wrapper">
                <GatsbyImage className="page-header-image" image={headerImage} alt={image}></GatsbyImage>
            </div>
            <span className="page-header-overlay"></span>
            <div className="page-header-text">
                <RedLinedHeader
                    fg_color="platinum-fg"
                    text={title}
                ></RedLinedHeader>
                <p>{text}</p>
            </div>
        </div>
    );
}