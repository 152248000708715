// Packages
import React from 'react';

// Components
import Layout from '../components/Layout';
import PageHeader from '../components/reusables/PageHeader';
import CoachesContent from '../components/coaches/CoachesContent';
import FooterContact from '../components/reusables/FooterContact';
import HeadMetadata from '../components/HeadMetadata';

export default function Coaches() {
    return (
        <Layout>
            <HeadMetadata title="Coaches"></HeadMetadata>
            <PageHeader
                image="patrick-front.jpg"
                title="MEET OUR COACHES"
                text="Our coaches are here to help you achieve your goals, whether big or small. They are focused on providing you the best training and instructions to reach your full potential."
            ></PageHeader>
            <CoachesContent></CoachesContent>
            <FooterContact
                bg_color="platinum"
                fg_color="rich-black-fogra"
            ></FooterContact>
        </Layout>
    );
}