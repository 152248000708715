import { v4 as uuidv4 } from 'uuid';

export default function coachesData() {
    return (
        [
            {
                id: uuidv4(),
                firstName: "KYLE",
                lastName: "CARDINAL",
                texts: [
                    {
                        id: uuidv4(),
                        text: "Black Belt trained and certified Instructor, Professor Cardinal is a Brazilian Jiu-Jitsu (also known as BJJ) Specialist, training in Gracie Jiu-Jitsu since 1994. Professor Cardinal has been influential as a pioneer of the Martial Art and Sport in Edmonton, contributing many hours to the development of athletes across Western Canada, with his focus on athletes in Edmonton and surrounding areas.",
                    },
                    {
                        id: uuidv4(),
                        text: "Professor Cardinal was Edmonton's 1st Black Belt in 2009 by famed Gracie Jiu-Jitsu Black Belt Rodrigo Munduruca, Canada's most decorated Jiu-Jitsu competitor under the legendary Royler/Rolker Gracie. With 18 years of dedication to Gracie Jiu-Jitsu, Professor Cardinal uses his experience in BJJ to benefit individuals at all levels of training such as, top athletes in BJJ and MMA, training to achieve their dreams at elite level competition, lifestyle individuals who train to keep their bodies in shape and their minds healthy, self-defense - for those who train for health and safety in a world with crime, inquisitive individuals who train to learn about BJJ and know more about the sport.",
                    },
                    {
                        id: uuidv4(),
                        text: "Professor Cardinal is a believer in all Martial Arts and how training the body also trains and disciplines the mind. He also holds a brown belt in Judo and has trained many years as a child in boxing. His knowledge in the sport has allowed him the privilege to compete as an athlete and now travel with contenders and corners them all over the world including events such as: PRIDE FIGHTING CHAMPIONSHIPS, UFC, ELITE XC, and many more.",
                    },
                ],
                image: "kyle.jpg",
            },
            {
                id: uuidv4(),
                firstName: "PATRICK",
                lastName: "REGO",
                texts: [
                    {
                        id: uuidv4(),
                        text: "Instructor Rego is a certified brown belt trained under professor Kyle Cardinal. His roots are from Boxing, and transitioned to Brazilian Jiu Jitsu after immediately falling in love with the sport. He founded Team Galo BJJ and now teaches and supervises all the classes in the club. He firmly believes that the time you spend the mats, correlates to your success in the sport.",
                    },
                ],
                image: "patrick.jpg",
            },
            {
                id: uuidv4(),
                firstName: "NIKI",
                lastName: "MOON-KEČA",
                texts: [
                    {
                        id: uuidv4(),
                        text: "Instructor Niki is a purple belt under professor Kyle Cardinal and instructor Patrick Rego. She has been training and competing in brazilian jiu-jitsu since 2016 and teaching under instructor Rego since 2021. Instructor Niki is trained formally in both gi and no-gi. She instructs combat jiu-jitsu and women’s only classes. She is also assistant instructor for kid’s classes, under instructor Rego. ",
                    },
                    {
                        id: uuidv4(),
                        text: "Instructor Niki has participated in competitive sport since she was a child, and fell in love with jiu-jitsu upon her first few classes. She is passionate about teaching and sharing her knowledge and hopes to instill this passion in others. Her hopes are to contribute to the push for more women in jiu-jitsu, as this sport develops and grows in popularity. She also hopes to bring attention to combat jiu-jitsu, as it is true to the origins of jiu-jitsu. She firmly believes that practicing combat jiu-jitsu is not only beneficial for sport purposes, but for self-defense outside of class. ",
                    },
                ],
                image: "niki.jpg",
            },
        ]
    );
}