// Packages
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion'

// Animation
import { pushItemUp } from '../../data/animationData';

// Components

export default function Coach({coach, image}) {
    return (
        <motion.div className="coach" variants={pushItemUp}>
            <div className="coach-image-wrapper">
                <GatsbyImage className="coach-image" image={image} alt={coach.image}></GatsbyImage>
            </div>            
            <div className="coach-name">
                <h2>{coach.firstName}</h2>
                <h2>{coach.lastName}</h2>
            </div>
            <div className="coach-description">
                {coach.texts.map(t =>
                    <p key={t.id}> {t.text} </p>
                )}
            </div>
        </motion.div>
    );
}